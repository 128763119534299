import React from "react"

// Components
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Section from "../components/Section"
import BlogCard from "../components/BlogCard"
import styled from "styled-components"
import Filters from "../components/Filters"

const BlogContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
`

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { nodes } = data.posts

  return (
    <Layout pageTitle={tag}>
      <Filters currentTag={tag} tags={data.tags.group} />
      <Section>
        <BlogContainer>
          {nodes.map(post => (
            <BlogCard key={post.id} to={post.path.alias} post={post} />
          ))}
        </BlogContainer>
      </Section>
    </Layout>
  )
}

/* Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
} */

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    posts: allNodeBlogs(
      limit: 2000
      sort: { fields: created, order: DESC }
      filter: {
        relationships: {
          field_category: { elemMatch: { name: { in: [$tag] } } }
        }
      }
    ) {
      totalCount
      nodes {
        title
        id
        path {
          alias
        }
        field_description
        relationships {
          field_blog_image {
            drupal_id
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5, width: 800)
              }
            }
          }
          field_category {
            name
          }
        }
      }
    }
    tags: allNodeBlogs {
      group(field: relationships___field_category___name) {
        fieldValue
        totalCount
      }
    }
  }
`
